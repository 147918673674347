<template>
    <section class="product-event">
        <div class="product-event__header">
            <h2 class="product-event__title">{{ title }}</h2>
        </div>
        <div class="product-event__body">
            <div class="wrap">
                <ul class="event-list">
                    <li class="event-list__item" v-for="(event,i) in events" :key="i">
                        <div class="event-list__item-wrap">
                            <div class="event-list__item-container">
                                <div class="event-list__item-icon-container">
                                    <img class="event-list__item-icon" :src="require('@/assets/events/'+ event.icon +'.png')" alt="events">
                                    <span class="event-list__item-badge"></span>
                                </div>
                            </div>
                            <div class="event-list__item-container">
                                <div>
                                    <h2 class="event-list__item-title">{{ event.title[lang] }}</h2>
                                    <p class="event-list__item-description">{{ event.description[lang] }}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="product-event__buttons-container">
                    <div class="product-event__buttons">
                        <a class="button button-transparent"
                           :href="require('@/assets/download/' + buttons.btn_product.url + '.png')"
                           download
                           v-if="buttons.btn_product"
                        >
                           {{ $t('buttons.Pro {name} Sales Sheet', { name: buttons.btn_product.title }) }}
                        </a>
                        <a class="button button-default"
                           href="https://prometheuspro.zohobookings.com/#/customer/meetings"
                           target="_blank"
                           v-if="buttons.btn"
                        >
                           {{ $t('buttons.' + buttons.btn.title ) }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "ProductEventList",
    props:{
        data:{
            type: [Object,Array],
            default: () => { return {} }
        }
    },
    computed:{
        lang(){
            return this.$i18n.locale
        },
        title(){
            return  this.data?.title[this.lang]
        },
        events(){
            return this.data?.eventList
        },
        buttons(){
            return this.data?.buttons
        }
    }
}
</script>

<style scoped>

</style>