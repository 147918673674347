<template>
    <section class="main">
        <HeadSectionMain :data="head"/>
        <OurProducts :data="ourPartners" style="background: white"/>
        <template v-for="(product, i) in listProducts" :key="i">
            <productComponent :data="product"
                              class="track-reverse"
                              :class="[
                                  i%2 && 'reverse',
                                  i === 0 && 'first',
                                  (i === listProducts.length - 1) && 'last'
                              ]"
            />
        </template>
        <infoComponent style="background: white; padding-bottom: 80px"/>
    </section>
</template>

<script>
export default {
    name: "trackPage",
    data() {
        return {
            jsonFile: ''
        }
    },
    computed: {
        head(){
            return this.jsonFile?.head
        },
        ourPartners(){
            return this.jsonFile?.our_products
        },
        listProducts() {
            return this.jsonFile?.list_products
        }
    },
    created() {
        this.jsonFile = require('../../pages/track.json');
    },
    mounted() {
    }
}
</script>

<style lang="scss">
.main {
    .product-item {
        &.first,
        &.last {
            background: white;
        }
    }
    .product-item__flex {
        flex-direction: row;
    }
    .reverse {
        background: #F9F9F9;
        &.product-item {
            .product-item__flex {
                flex-direction: row-reverse;
            }
        }
    }
}
</style>