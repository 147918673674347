<template>
    <section class="our-partners">
        <swiper class="our-partners__swiper" v-bind="swiperOptions">
            <swiper-slide class="our-partners__slide" v-for="(partner,i) in partners" :key="i">
                <a><img :src="require('@/assets/pratners/'+ partner.title +'.png')" :alt="partner.title" :title="partner.title"></a>
            </swiper-slide>
        </swiper>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay'

import { Autoplay, } from "swiper";
export default {
    name: "OurPartners",
    props:{
        data:{
            type: [Object,Array],
            default: () => { return {} }
        },
    },
    data(){
        return {
            swiperOptions:{
                centeredSlides:true,
                slidesPerView: 1.5,
                loopedSlides: 4.5,
                spaceBetween: 150,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                },
                breakpoints:{
                    370:{
                        slidesPerView: 2.1
                    },
                    480:{
                        slidesPerView: 2.5
                    },
                    1050:{
                        slidesPerView: 4
                    }
                },
                loop: true,
                observe: true,
                observeParents: true,
                observeSlideChildren: true,
                speed:1000,
                modules:[Autoplay]
            }
        }
    },
    components:{
        Swiper,
        SwiperSlide,
    },
    computed:{
        title(){
            return  this.data?.title[this.$i18n.locale]
        },
        partners(){
            return this.data?.partners
        },
        description(){
            return  this.data?.description[this.$i18n.locale]
        },
    }
}
</script>

<style scoped>

</style>