<template>
    <div class="tabs">
        <div class="wrap tabs__wrap">
            <ul class="tabs__list">
                <template v-for="item in list" :key="item.id">
                    <li class="tabs__item" :class="[item.id == modelValue && 'tabs__item--active']" @click="$emit('update:modelValue', item.id)">
                        {{ item.title }}
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "prohubTabs",
    emits: ["update:modelValue"],
    props: {
        list: Array,
        modelValue: String,
    },
}
</script>

<style scoped lang="scss">
.tabs {
    margin-bottom: 50px;
    &__list {
        display: flex;
    }
    &__item {
        //flex: 1 33.3%;
        width: 33.3%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-bottom: solid 5px #E7E7E7;
        color: #7E8FA3;
        padding: 30px;
        cursor: pointer;

        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    &__item--active {
        border-bottom: solid 5px #00A2EE;
        color: #0F3A6C;

        &:before {
            content: '';
            display: block;
            width: 32px;
            height: 32px;
            position: absolute;
            bottom: -16px;
            left: 50%;
            transform: translateX(-50%);
            background: linear-gradient(90deg, #1068D0 0%, #51E0FA 100%);
            border-radius: 100%;
        }
    }
}
@media all and (max-width: 900px) {
    .tabs {
        &__item {
            font-size: 22px;
        }
    }
}
@media all and (max-width: 450px) {
    .tabs {
        &__list {
            flex-direction: column;
        }
        &__item {
            width: 100%;
        }
    }
}
</style>