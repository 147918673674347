<template>
    <HeaderComponent/>
    <SectionContent/>
    <FooterComponent/>
</template>

<script>

export default {
    name: 'App',
    components: {},
    mounted() {
        let lang = localStorage.getItem('lang')
        if (lang) {
            this.$i18n.locale = lang
        }
    }
}
</script>

<style lang="scss">
@import '~@/scss/main.scss';
</style>
