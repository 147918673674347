import { createI18n } from 'vue-i18n'

const locale = "en" // default locale
import en from "@/lang/en.json";
import es from "@/lang/es.json";
import pt from "@/lang/pt.json";

const messages = {
    es,
    pt,
    en
}

const i18n = createI18n({
    locale,
    fallbackLocale: 'en',
    formatFallbackMessages: true,
    messages,
    //allowComposition: true, // you need to specify that!
})

export default i18n