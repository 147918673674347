<template>
    <section class="main">
        <HeadSectionMain :data="head"/>
        <OurProducts :data="ourPartners" style="background:  #F9F9F9"/>
        <h2 class="title" style="background:  #F9F9F9">{{ title[lang]}}</h2>
        <template v-for="(product, i) in listProducts" :key="i">
            <productComponent :data="product"
                              class="color-gray"
                              :class="[
                                  i%2 && 'color-white',
                              ]"
            >
                <template v-slot:sensors v-if="product?.sensors">
                    <div class="sensors-wrap">
                        <h5 style="margin-bottom: 20px">{{ product.sensors.title[lang] }}</h5>
                        <ul class="sensors">
                            <li v-for="(sensor, i) in product?.sensors?.list" :key="i" class="sensors__item">
                                <div class="sensors__img">
                                    <img :src="require('@/assets/sensors/'+sensor.img)" :alt="sensor.img">
                                </div>
                                <h6 class="sensors__title">
                                    {{ sensor.name }}
                                </h6>
                            </li>
                        </ul>
                    </div>
                </template>
            </productComponent>
        </template>
        <ourMobileApplication :data="our_mobile_app"/>
        <infoComponent class="white-bg"/>
    </section>
</template>

<script>
export default {
    name: "visionPage",
    data() {
        return {
            jsonFile: '',
            title: {
                en: "Vision Features",
                es: "Vision Features",
                pt: "Vision Features",
            }
        }
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        head(){
            return this.jsonFile?.head
        },
        ourPartners(){
            return this.jsonFile?.our_products
        },
        listProducts() {
            return this.jsonFile?.list_products
        },
        our_mobile_app() {
            return this.jsonFile?.our_mobile_app
        }
    },
    created() {
        this.jsonFile = require('../../pages/vision.json');
    },
    mounted() {
    }
}
</script>

<style lang="scss" scoped>
.main {
    .title {
        text-align: center;
        padding: 50px 0 0 0;
    }
    .product-item {
        &.color-gray {
            background: #F9F9F9;
        }
        &.color-white {
            background: white;
        }
    }
    .white-bg {
        position: relative;
        background: white;
        padding: 85px 0;
        margin-top: -250px;
    }
    .sensors {
        display: grid;
        grid-template-columns: repeat(3, 100px);
        gap: 10px;

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        &__title {
            text-align: center;
        }
    }
}
@media all and (max-width: 1550px) {
    .main {
        .white-bg {
            margin-top: -15%;
        }
        .sensors-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}
//@media all and (max-width: 900px) {
//    .main {
//        .white-bg {
//            margin-top: -20%;
//        }
//        .sensors-wrap {
//            display: flex;
//            flex-direction: column;
//            align-items: center;
//        }
//    }
//}
</style>