<template>
    <section class="about-product">
        <img class="about-product__bg" :src="require('@/assets/Vector.png')" alt="">
        <div class="wrap">
            <div class="about-product__container">
                <ul class="about-product__list">
                    <li class="about-product__item info-card" v-for="(item,i) in listInfo" :key="i">
                        <div class="info-card__header">
                            <div class="info-card__icon-container">
                                <img class="info-card__icon"  :src="require('@/assets/about-product/'+ item?.icons?.icon +'.png')" alt="">
                                <img class="info-card__badge" v-if="item?.icons?.badge" :src="require('@/assets/about-product/lock.svg')" alt="">
                                <div class="info-card__badge-text" v-if="item?.icons?.text"> {{item?.icons?.text}} </div>
                            </div>
                            <h3 class="info-card__title">{{ item?.title[lang] }}</h3>
                        </div>
                        <p class="info-card__description">{{ item?.description[lang] }}</p>
                    </li>
                </ul>
            </div>
            <div class="about-product__container">
                <h2 class="about-product__title" v-html="title"></h2>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AboutProduct",
    props:{
        data:{
            type: [Object,Array],
            default: () => { return {} }
        }
    },
    computed:{
        title(){
            return  this.data?.title[this.lang]
        },
        lang(){
            return this.$i18n.locale
        },
        description(){
            return  this.data?.description[this.lang]
        },
        listInfo(){
            return this.data?.listInfo
        }
    },
    methods:{
    },
    mounted() {
        let str = 'Protect Your %br% Investments'
      console.log(str.replace('%br%','LOX'))
    }
}
</script>

<style scoped>

</style>