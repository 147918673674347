<template>
    <a class="button button-default"
       :href="!link || link === '#' ? 'https://calendly.com/dealer-prometheus-syntra' : link"
       target="_blank"
    >
        {{ text }}
    </a>
</template>

<script>
export default {
    name: "ButtonBookMeeting",
    props: {
        link: String,
    },
    data() {
        return {
            title: {
                en: "Book A Meeting",
                es: "Book A Meeting",
                pt: "Book A Meeting"
            },
        }
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        text() {
            return this.title[this.lang]
        }
    }
}
</script>

<style scoped>

</style>