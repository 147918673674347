<template>
    <section class="main">
        <HeadSectionMain :data="head"/>
        <OurProducts :data="ourPartners"/>
        <prohubTabs :list="_listTypes" v-model="type"/>
        <template v-for="(product, i) in listProducts" :key="i">
            <productComponent
                class="prohub"
                :class="[]"
                :data="product"
            />
        </template>
        <ourMobileApplication :data="our_mobile_app"/>
        <infoComponent class="white-bg"/>
    </section>
</template>

<script>
export default {
    name: 'HomePage',
    data() {
        return {
            jsonFile: '',
            listTypes: [
                {id: 'tms', title: {en: "TMS", es: "TMS", pt: "TMS"}},
                {id: 'eld', title: {en: "ELD", es: "ELD", pt: "ELD"}},
                {id: 'compliance', title: {en: "Compliance", es: "Compliance", pt: "Compliance"}},
            ],
            type: 'tms'
        }
    },
    components: {},
    computed:{
        head(){
            return this.jsonFile?.head
        },
        lang() {
            return this.$i18n.locale
        },
        _listTypes() {
            return this.listTypes.map(l => ({...l, title: l.title[this.lang]}))
        },
        aboutUs(){
            return this.jsonFile?.about_us
        },
        ourVision(){
            return this.jsonFile?.our_vision
        },
        ourCulture(){
            return this.jsonFile?.our_culture
        },
        ourPartners(){
            return this.jsonFile?.our_products
        },
        products(){
            return this.jsonFile?.products
        },
        contact(){
            return this.jsonFile?.contact
        },
        productsList(){
            return this.jsonFile?.products_list
        },
        info(){
            return this.jsonFile?.info
        },
        listProducts() {
            return this.jsonFile?.list_products?.filter(l => l.type === this.type)
        },
        our_mobile_app() {
            return this.jsonFile?.our_mobile_app
        }
    },
    mounted() {
    },
    created() {
        this.jsonFile = require('../../pages/pro-hub.json')
    }
}
</script>
<style lang="scss" scoped>
.dabble-bg {
    position: relative;
    z-index: 1;
    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 50%;
        top: 0;
        left: 0;
        z-index: -1;
    }
    &:before {
        background: #F0F2F4;
    }
    &:after {
        top: 50%;
        background: #F9F9F9;
    }
}
@media all and (max-width: 1550px) {
    .main {
        .white-bg {
            margin-top: -15%;
        }
    }
}
</style>