<template>
    <section class="our-mobile">
        <div class="wrap">
            <h2 class="our-mobile__title text-center"> {{ title }} </h2>
            <p class="our-mobile__description text-center"> {{ description }} </p>
            <div class="our-mobile__img-wrap">
                <img class="our-mobile__img" :src="require('@/assets/our-product/'+ img + '.png')" :alt="img">
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "ourMobileApplication",
    props: {
        data: Object
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        title() {
            return this.data.title[this.lang]
        },
        description() {
            return  this.data.description[this.lang]
        },
        img() {
            return this.data.img
        }
    }
}
</script>

<style scoped lang="scss">
.our-mobile {
    padding-top: 50px;
    background: #F9F9F9;
    .text-center {
        text-align: center;
    }
    &__title {
        margin-bottom: 15px;
    }
    &__description {
        margin-bottom: 30px;
    }
    &__img-wrap {
        display: flex;
        justify-content: center;
    }
    &__img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }
}

</style>