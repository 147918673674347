<template>
    <section class="product-item">
        <div class="wrap product-item__wrap product-item__flex">
            <div class="product-item__img">
                <img :src="require('@/assets/our-product/'+img+'.png')" alt="">
                <div class="product-item__buttons">
                    <ButtonLearnMore v-if="links?.img?.more" :link="links?.img?.more"/>
                    <ButtonBookDemo v-if="links?.img?.demo"/>

                    <ButtonLearnMoreFill class="mobile" v-if="links?.more_fill" :link="links?.more_fill"/>
                    <ButtonComingSoon class="mobile" v-if="links?.coming_soon" :link="links?.coming_soon"/>

                    <ButtonLearnMore v-if="links.more" :link="links.more" class="btn-mob"/>
                    <ButtonBookDemo v-if="links.demo" class="btn-mob"/>
                </div>
            </div>
            <div class="product-item__text">
                <div class="text-center" v-if="logo">
                    <img :src="require('@/assets/logo/'+logo+'.svg')" :alt="logo">
                </div>
                <h2 class="text-center" v-if="title">{{ title }}</h2>
                <p v-if="description">{{ description }}</p>
                <div class="product-item__slot">
                    <slot name="sensors"></slot>
                </div>
                <div class="product-item__buttons">
                    <ButtonLearnMore v-if="links.more" :link="links.more"/>
                    <ButtonLearnMoreFill v-if="links.more_fill" :link="links.more_fill"/>
                    <ButtonBookDemo v-if="links.demo"/>
                    <ButtonComingSoon v-if="links.coming_soon" :link="links.coming_soon"/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "productComponent",
    props: {
        data: Object
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        title() {
            return this.data.title[this.lang]
        },
        description() {
            return this.data.description[this.lang]
        },
        img() {
            return this.data.img
        },
        links() {
            return this.data.links
        },
        logo() {
            return this.data.logo
        }
    }
}
</script>

<style scoped lang="scss">
.product-item {
    background: white;
    padding: 50px 0;

    .mobile {
        display: none;
    }
    .btn-mob {
        display: none;
    }
    &__flex {
        display: flex;
        gap: 100px;
        flex-direction: row-reverse;
        align-items: center;
    }
    &.first,
    &.last {
        background: #F9F9F9;
    }
    &.reverse {
        .product-item__flex {
            flex-direction: row;
        }
    }
    &__wrap {
        max-width: calc(100vw - 30%);
    }
    &__img {
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 64px;
    }
    &__text {
        width: 40%;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    &__buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
    }
    img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }
}
.prohub {
    .product-item {
        &__flex {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;
        }
        &__img,
        &__text {
            width: 100%;
            height: 100%;
            grid-row-start: 1;
        }
        &__text {
            justify-content: space-around;
        }
        &__img {
            grid-column-start: 2;
        }
    }
}
@media all and(max-width: 900px) {
    .product-item {
        padding: 70px 0;
        &__wrap {
            max-width: calc(100vw - 15%);
        }
        &__flex {
            flex-direction: column-reverse;
            justify-content: center;
            gap: 40px;
        }
        &.reverse {
            .product-item__flex {
                flex-direction: column-reverse;
                justify-content: center;
            }
        }
        &__text {
            width: 100%;
            .product-item__buttons {
                display: none;
            }
        }
        &__img {
            width: 100%;
        }
        .text-center {
            text-align: center;
        }
        .btn-mob {
            display: flex;
        }
    }
    .prohub {
        .product-item {
            &__flex{
                grid-template-columns: 1fr;
                grid-template-rows: auto;
            }
            &__img,
            &__text{
                grid-column-start: auto;
                grid-row-start: auto;
            }
            &__text {
                grid-row-start: 1;
            }
            &__img {
                grid-row-start: 2;
            }
        }
        .mobile {
            display:flex;
        }
    }
}
</style>