<template>
    <section class="our-products">
        <swiper class="our-products__swiper" v-bind="swiperOptions">
            <swiper-slide class="our-products__slide" v-for="(partner,i) in partners" :key="i">
                <a class="our-products__item" :href="partner.link">
                    <div class="our-products__wrap-img">
                        <img class="our-products__img" :src="require('@/assets/our-product/'+ partner.img +'.png')" :alt="partner.img" :title="partner.title">
                    </div>
                    <div class="our-products__text">
                        <div>
                            <h5 class="our-products__title">{{ partner.title[lang] }}</h5>
                            <p class="our-products__description">{{partner.description[lang]}}</p>
                        </div>
                        <img :src="require('@/assets/arrow-line.svg')" alt="arrow" style="width: 15px">
                    </div>
                </a>
            </swiper-slide>
        </swiper>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay'

import { Autoplay, } from "swiper";
export default {
    name: "OurPartners",
    props:{
        data:{
            type: [Object,Array],
            default: () => { return {} }
        },
    },
    data(){
        return {
            swiperOptions:{
                centeredSlides:true,
                slidesPerView: 1.5,
                loopedSlides: 4.5,
                spaceBetween: 10,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                },
                breakpoints:{
                    320:{
                        slidesPerView: 1.1
                    },
                    500:{
                        slidesPerView: 1.4
                    },
                    690:{
                        slidesPerView: 1.4
                    },
                    845:{
                        slidesPerView: 1.8
                    },
                    941:{
                        slidesPerView: 2
                    },
                    1491:{
                        slidesPerView: 3.2
                    },
                    1645:{
                        slidesPerView: 3.5
                    },
                    1870:{
                        slidesPerView: 4
                    }
                },
                loop: true,
                observe: true,
                observeParents: true,
                observeSlideChildren: true,
                speed:1000,
                modules:[Autoplay]
            }
        }
    },
    components:{
        Swiper,
        SwiperSlide,
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        partners(){
            return this.data
        },
    }
}
</script>

<style scoped lang="scss">
.our-products {
    padding: 70px 0 140px 0;
    &__item {
        display: flex;
        background: white;
        height: 200px;
        width: 465px;
        padding: 25px 15px;
        border-radius: 16px;
        gap: 15px;
    }
    &__wrap-img {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__text {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__description {
        //width: 160px;
    }
    &__img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
    }
    //&__wsiper {
    //    height: 208px;
    //}
    //&__slide {
    //    background: white;
    //    width: 440px;
    //    height: 208px;
    //}
    //a {
    //    height: 100%;
    //}
}
@media all and (max-width: 690px) {
    .our-products {
        &__item {
            width: 100%;
        }
    }
}
@media all and (max-width: 350px) {
    .our-products {
        &__item {
            width: 100%;
            height: 250px;
        }
    }
}
</style>