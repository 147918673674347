import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/HomePage.vue'
import ProductPage from '@/views/ProductPage.vue'
import trackPage from '@/views/trackPage.vue'
import visionPage from '@/views/visionPage.vue'
import assetsPage from '@/views/assetsPage.vue'
import reeferPage from '@/views/reeferPage.vue'
import cargoPage from '@/views/cargoPage.vue'
import eldPage from '@/views/eldPage.vue'
import dealerPage from '@/views/dealerPage.vue'
import ProHUBPage from '@/views/ProHUBPage.vue'
// import ContactForm from "@/components/base/ContactForm";

const routes = [
  {
    path: '/product/:product',
    name: 'Products',
    component: ProductPage
  },
  {
    path: '/product/track',
    name: 'Products.track',
    component: trackPage
  },
  {
    path: '/product/vision',
    name: 'Products.vision',
    component: visionPage
  },
  {
    path: '/product/assets',
    name: 'Products.assets',
    component: assetsPage
  },
  {
    path: '/product/reefer',
    name: 'Products.reefer',
    component: reeferPage
  },
  {
    path: '/product/cargo',
    name: 'Products.cargo',
    component: cargoPage
  },
  {
    path: '/product/eld',
    name: 'Products.eld',
    component: eldPage
  },
  {
    path: '/dealer',
    name: 'Dealer',
    component: dealerPage
  },
  {
    path: '/pro-hub',
    name: 'ProHub',
    component: ProHUBPage
  },
  // {
  //   path: '/email',
  //   name: 'Email',
  //   component: ContactForm
  // },
  {
    path: '/:pathMatch(.*)*',
    name: 'Main',
    component: HomePage
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach((to) => {
  if(to.name == 'Main' && to.path != '/'){
    return '/'
  }
  return true
})
export default router
