<template>
    <section class="main">
        <HeadSectionMain :data="head"/>
        <OurProducts :data="ourPartners"/>
        <h2 class="title">{{ title[lang]}}</h2>
        <template v-for="(product, i) in listProducts" :key="i">
            <productComponent :data="product"
                              :class="[
                                  i%2 && 'color-gray',
                              ]"
            >
            </productComponent>
        </template>
        <ourMobileApplication :data="our_mobile_app"/>
        <infoComponent class="white-bg"/>
    </section>
</template>

<script>
export default {
    name: "cargoPage",
    data() {
        return {
            jsonFile: '',
            title: {
                en: "Cargo Monitoring",
                es: "Cargo Monitoring",
                pt: "Cargo Monitoring",
            }
        }
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        head(){
            return this.jsonFile?.head
        },
        ourPartners(){
            return this.jsonFile?.our_products
        },
        listProducts() {
            return this.jsonFile?.list_products
        },
        our_mobile_app() {
            return this.jsonFile?.our_mobile_app
        }
    },
    created() {
        this.jsonFile = require('../../pages/cargo.json');
    },
    mounted() {
    }
}
</script>

<style lang="scss" scoped>
.main {
    .title {
        text-align: center;
        background: white;
        padding: 50px 0 0 0;
    }
    .product-item {
        &.color-gray {
            background: #F9F9F9;
        }
        &.color-white {
            background: white;
        }
    }
    .white-bg {
        position: relative;
        background: white;
        padding: 85px 0;
        margin-top: -250px;
    }
    .sensors {
        display: grid;
        grid-template-columns: repeat(3, 100px);
        gap: 10px;

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        &__title {
            text-align: center;
        }
    }
}
@media all and (max-width: 1550px) {
    .main {
        .white-bg {
            margin-top: -15%;
        }
        .sensors-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}
</style>