<template>
    <section class="head-section">
        <video class="head-section__img" :src="video" autoplay muted playsinline  loop="loop" v-if="video" ref="video"></video>
        <div v-else style="position: relative; width: 100%">
            <img class="head-section__img" :src="require(`@/assets/products/${data.img}.png`)" :alt="data.img">
            <slot></slot>
        </div>
        <slot name="button"></slot>
        <ButtonBookDemo style="opacity: 0.7" class="book-a-demo" v-if="!$slots?.button"/>
    </section>
</template>
<script>

export default {
    name: "HeadSectionMain",
    props:{
        "data":{
            type: [Object,Array],
            default: () => { return {} }
        }
    },
    data(){
        return {
        }
    },
    components:{
    },
    computed:{
        roteName() {
            return this.$route?.name
        },
        lang(){
            return this.$i18n.locale
        },
        title(){
            return  this.data?.title[this.lang]
        },
        description(){
            return  this.data?.description[this.lang]
        },
        sliderList(){
            return this.data?.slider
        },
        video() {
            return this.data?.video
        }
    },
    // watch: {
    //     roteName: {
    //         handler: function (nval) {
    //             console.log(nval)
    //             this.play()
    //         },
    //         immutable: true
    //     },
    // },
    methods: {
        play() {
            // if(!this.$refs.video.paused && !this.$refs.video.ended) {
                this.$refs?.video?.play()
            // }
            // console.log('no')
        }
    },
    mounted() {
        if(this.video) {
            this.play()
        }
        // document.addEventListener("DOMContentLoaded", this.play);
    },
    beforeUnmount() {
        // document.removeEventListener('DOMContentLoaded', this.play)
    }
}
</script>

<style scoped lang="scss">
video::-webkit-media-controls {
    display:none !important;
}
.head-section {
    position: relative;
    height: 100%;
    max-height: calc(100vh - 95px);
    overflow: hidden;
    display: flex;
    &__img {
        width: 100%;
        object-fit: fill;
    }
    .button-default {
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.dealer-header {
    height: auto;
    max-height: 550px;
    position: relative;
    .head-section__img {
        object-fit: cover;
    }
}
@media all and (max-width: 650px) {
    .head-section {
        .button-default {
            bottom: 20px;
        }
    }
}
@media screen and (max-width: 1080px) {
    .dealer-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .book-a-demo {
            margin-top: 20px;
            transform: none;
            position: static;
        }
    }
}
</style>