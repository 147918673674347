<template>
    <section class="our-vision">
        <div class="row">
            <div class="our-vision__wrap our-vision__img">
                <img :src="require('@/assets/our-vision-2.png')" alt="our-vision">
            </div>
            <div class="our-vision__wrap our-vision__text">
                <div class="our-vision__title"><h2>{{ title }}</h2></div>
                <div class="our-vision__description"><h3>{{description}}</h3></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AboutUs",
    props:{
        data:{
            type: [Object,Array],
            default: () => { return {} }
        }
    },
    computed:{
        title(){
            return  this.data?.title[this.$i18n.locale]
        },
        description(){
            return  this.data?.description[this.$i18n.locale]
        }
    }
}
</script>

<style scoped>

</style>