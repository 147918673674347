<template>
    <a class="button button-default"
       :href="link || '#'"
       target="_blank"
    >
        {{ text }}
    </a>
</template>

<script>
export default {
    name: "ButtonComingSoon",
    props: {
        link: String,
    },
    data() {
        return {
            title: {
                en: "Coming Soon",
                es: "Coming Soon",
                pt: "Coming Soon"
            },
        }
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        text() {
            return this.title[this.lang]
        }
    }
}
</script>

<style scoped>

</style>