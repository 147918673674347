<template>
    <a class="button button-outline"
       :href="link || '#'"
    >
        {{ text }}
    </a>
</template>

<script>
export default {
    name: "ButtonLearnMore",
    props: {
        link: String,
    },
    data() {
        return {
            title: {
                en: "Learn More",
                es: "Learn More",
                pt: "Learn More"
            },
        }
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        text() {
            return this.title[this.lang]
        }
    }
}
</script>

<style scoped>

</style>