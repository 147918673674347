<template>
    <section class="contact-form">
        <div class="wrap">
            <h2 class="contact-form__title">Partnership</h2>
            <form class="contact-form__body" id="contactForm" name="contact_form" @submit.prevent="sendEmail">
                <ul class="contact-form__block">
                    <li class="contact-form__item" v-for="(element, i) in form" :key="i">
                        <label class="contact-form__item__label">
                            <h3 class="contact-form__item__title">
                                <span class="contact-form__item__title-badge" v-if="element.required" >*</span>
                                {{ element.label }}:
                            </h3>
                            <div class="contact-form__item__input-block">
                                <input
                                    class="contact-form__item__input"
                                    :type="element.type"
                                    :name="element.id"
                                    :required="element.required"
                                    v-model="params[element.id]"
                                >
                            </div>
                        </label>
                    </li>
                </ul>
                <div class="contact-form__checkbox">
                    <div>
                        <input type="checkbox" name="privacy_policy" v-model="params['privacy_policy']" required>
                    </div>
                    <p class="contact-form__checkbox__text">
                        Yes, I would like to receive marketing communications regarding Prometheus products, service, newsletters and events.
                        <br>
                        Your information will be used in accordance with Prometheus’s
                        <a href="https://app.termly.io/document/privacy-policy/db1331f8-4fd5-4b42-8c99-70d89bcc5b37"
                           target="_blank"
                        >
                            Privacy Policy.
                        </a>
                    </p>
                </div>
                <div class="contact-form__footer">
                    <button type="submit"
                            class="button button-default"
                            :class="[(!params['privacy_policy'] || !inProgress) && 'button-disabled']"
                            :disabled="!params['privacy_policy'] || !inProgress"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";

const def_params = {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    company: '',
    company_website: '',
    city: '',
    additional_company_details: '',
    privacy_policy: false
};

export default {
    name: "ContactForm",
    data() {
        return {
            inProgress: true,
            toast: useToast(),
            form: [
                {
                    id: 'first_name',
                    label: 'First name',
                    type: 'text',
                    required: true,
                },
                {
                    id: 'last_name',
                    label: 'Last Name',
                    type: 'text',
                    required: true,
                },
                {
                    id: 'phone',
                    label: 'Phone',
                    type: 'tel',
                    required: true,
                },
                {
                    id: 'email',
                    label: 'E-main',
                    type: 'email',
                    required: true,
                },
                {
                    id: 'company',
                    label: 'Company',
                    type: 'text',
                    required: true,
                },
                {
                    id: 'company_website',
                    label: 'Company Website',
                    type: 'text',
                    required: true,
                },
                {
                    id: 'city',
                    label: 'City',
                    type: 'text',
                    required: true,
                },
                {
                    id: 'additional_company_details',
                    label: 'Additional company details',
                    type: 'text',
                },
            ],
            params: {...def_params}
        }
    },
    methods: {
        async sendEmail() {
            this.inProgress = false
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded()
            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha('sendEmail')

            // Do stuff with the received token.
            // console.log('recaptcha', token)

            axios({
                method: 'post',
                url: "/email.php",
                data: {...this.params, token},
            })
            .then((resp) => {
                // console.log('good', resp)
                if(resp.data.error){
                    console.error(resp.data.message)
                    this.toast.error(resp.data.message)

                    //'<div class="alert alert-danger"> Error! The security token has expired or you are a bot.</div>'
                } else {
                    console.log(resp.data.message)
                    this.toast.success(resp.data.message)
                    // '<p class="alert alert-success">Thank You! Your message has been successfully sent.</p>';
                    this.params = {...def_params}
                }
                this.inProgress = true
            })
            .catch((error) => {
                console.warn(error)
                //if(error.response.status == 403)
                //if(error.response.status == 500)
                {
                    let message = "Something went wrong, your message could not be sent."
                    this.toast.error(message)
                // 500:
                // <p className="alert alert-warning">Something went wrong, your message could not be sent.</p>
                // 403:
                // <p className="alert-warning">There was a problem with your submission, please try again.</p>
                }
                this.inProgress = true
            })
            // return false;
        }
    },
    mounted() {

    },
}
</script>

<style scoped>

</style>