<template>
    <section class="product-info">
        <div class="wrap">
            <div class="product-info__wrap">
                <div class="product-info__container">
                    <h2 class="product-info__title">{{ title }}</h2>
                    <h2 class="product-info__subtitle">{{ subTitle }}</h2>
                </div>
                <div class="product-info__container">
                    <h2 class="product-info__description">{{ description }}</h2>
                    <h2 class="product-info__sub-description" v-if="subDescription">{{ subDescription }}</h2>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "ProductInfoSections",
    props:{
        data:{
            type: [Object,Array],
            default: () => { return {} }
        }
    },
    computed:{
        title(){
            return  this.data?.title[this.$i18n.locale]
        },
        subTitle(){
            return this.data?.subTitle[this.$i18n.locale]
        },
        description(){
            return  this.data?.description[this.$i18n.locale]
        },
        subDescription(){
            return  this.data?.subDescription[this.$i18n.locale]
        }
    }
}
</script>

<style scoped>

</style>