<template>
    <section class="products products-aqua">
        <div class="wrap">
            <div class="products__wrap">
                <div class="product_row  row align-center our-culture-right-line">
                    <div class="product__wrap">
                        <h2 class="products__title">{{ title }}</h2>
                    </div>
                    <div class="product__wrap">
                        <h3 class="products__description">{{ description }}</h3>
                    </div>
                </div>
                <div class="product_row row justify-end">
                    <a class="button button-default"
                       href="https://prometheuspro.zohobookings.com/#/customer/meetings"
                       target="_blank"
                    >
                        Book a Demo
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "ProductsComponent",
    props:{
        data:{
            type: [Object,Array],
            default: () => { return {} }
        }
    },
    computed:{
        title(){
            return  this.data?.title[this.$i18n.locale]
        },
        description(){
            return  this.data?.description[this.$i18n.locale]
        }
    }
}
</script>

<style scoped>

</style>