<template>
    <main :class="page">
        <HeadProductSection :data="head" :page-name="pageName"/>
        <div class="bg-1">
            <ProductInfoSections :data="productInfo"/>
            <AboutProduct :data="aboutProduct"/>
        </div>
        <ProductEventList :data="aboutEvent"/>
<!--        <OtherProduct :data="OtherProduct"/>-->
        <ProductsComponent :data="OtherProduct"/>
        <ProductsListSlider :data="productsList"/>
        <ContactUs :data="contact"/>
        <ContactForm/>
        <InfoSection :data="info"/>
    </main>
</template>

<script>
import ContactForm from "@/components/base/ContactForm";

export default {
    name: "ProductPage",
    components: {ContactForm},
    data(){
        return{}
    },
    computed:{
        title(){
            return this.jsonFile.title
        },
        pageName(){
            return this.jsonFile.page_name
        },
        routeName(){
            return this.$route.name
        },
        routeMeta(){
            return this.$route.meta
        },
        jsonFile(){
            return require('../../pages/product-'+ this.$route.params.product +'.json')
        },
        page(){
            return this.jsonFile?.page
        },
        head(){
            return this.jsonFile?.head
        },
        productInfo(){
            return this.jsonFile?.productInfo
        },
        aboutProduct(){
            return this.jsonFile?.aboutProduct
        },
        aboutEvent(){
            return this.jsonFile?.productEventList
        },
        contact(){
            return this.jsonFile?.contact
        },
        productsList(){
            return this.jsonFile?.products_list
        },
        info(){
            return this.jsonFile?.info
        },
        OtherProduct(){
            return this.jsonFile?.other_product
        }
    },
    mounted() {
        console.log('mount', this.$route.path)
    },
    created() {

        // console.log('mount', this.$route.params.product)
    }
}
</script>

<style scoped>

</style>