<template>
    <section class="main">
        <HeadSectionMain :data="head" class="dealer-header">
            <template v-slot:default>
                <h2 class="dear-title">Master Channel Partner</h2>
            </template>
            <template v-slot:button>
                <ButtonBookMeeting class="book-a-demo"/>
            </template>
        </HeadSectionMain>
        <OurProducts :data="ourPartners"/>
        <dealerForm/>
        <ourMobileApplication :data="our_mobile_app"/>
        <infoComponent class="white-bg"/>
    </section>
</template>

<script>
export default {
    name: "assetsPage",
    data() {
        return {
            jsonFile: '',
            title: {
                en: "Dealer",
                es: "Dealer",
                pt: "Dealer",
            }
        }
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        head(){
            return this.jsonFile?.head
        },
        ourPartners(){
            return this.jsonFile?.our_products
        },
        listProducts() {
            return this.jsonFile?.list_products
        },
        our_mobile_app() {
            return this.jsonFile?.our_mobile_app
        }
    },
    created() {
        this.jsonFile = require('../../pages/dealer.json');
    },
    mounted() {
    }
}
</script>

<style lang="scss">
.main {
    .title {
        text-align: center;
        background: white;
        padding: 50px 0 0 0;
    }
    .product-item {
        &.color-gray {
            background: #F9F9F9;
        }
        &.color-white {
            background: white;
        }
    }
    .white-bg {
        position: relative;
        background: white;
        padding: 85px 0;
        margin-top: -250px;
    }
    .sensors {
        display: grid;
        grid-template-columns: repeat(3, 100px);
        gap: 10px;

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        &__title {
            text-align: center;
        }
    }
}
.dear-title {
    position: absolute;
    color: white;
    text-align: center;
    left: 50%;
    bottom: 200px;
    transform: translateX(-50%);
}
@media screen and (max-width: 1080px) {
    .dear-title {
        position: absolute;
        bottom: 20%;
    }
}
@media screen and (max-width: 700px) {
    .dear-title {
        font-size: 28px;
    }
}
@media screen and (max-width: 500px) {
    .dear-title {
        font-size: 20px;
    }
}
</style>
<style lang="scss">
.button.book-a-demo {
    margin-top: 20px;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    bottom: 60px;
}
@media all and (max-width: 650px) {
    .head-section {
        .button-default {
            bottom: 20px;
        }
    }
}
@media screen and (max-width: 1080px) {
    .dealer-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .book-a-demo {
            margin-top: 20px;
            transform: none;
            position: static;
        }
    }
}
</style>