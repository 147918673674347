<template>
    <section class="products-list-slider">
        <div>
            <swiper v-bind="sliderOptions">
                <swiper-slide class="products-list-slider__item" v-for="(product,i) in productList" :key="i">
                    <router-link :to="product.url" class="products-list-slider__item-container">
                        <div class="products-list-slider__item-img"><img :src="require('@/assets/prometheus.png')" alt="prometheus"></div>
                        <h3 class="products-list-slider__item-title">{{ product.title[$i18n.locale] }}</h3>
                    </router-link>
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/scss/navigation';
import 'swiper/css/autoplay'
import { Navigation, Autoplay } from 'swiper';
export default {
    name: "ProductsListSlider",
    props:{
        data:{
            type: [Object,Array],
            default: () => { return [] }
        }
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    data(){
        return {
            sliderOptions: {
                modules: [Navigation,Autoplay],
                slidesPerView: 2.1,
                spaceBetween: 50,
                navigation: false,
                loop: true,
                centeredSlides:true,
                initialSlide:2,
                observe:true,
                observerParents:true,
                observeSlideChildren:true,
                speed:1000,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                },
                breakpoints:{
                    750:{
                        slidesPerView: 3,
                    },
                    1350:{
                        slidesPerView: 5,
                        autoplay: false,
                        centeredSlides:false,
                        allowTouchMove:false,
                    }
                },
            }
        }
    },
    computed:{
        productList(){
            return this.data
        }
    }
}
</script>

<style scoped>

</style>